import { breakpointsTailwind } from '@vueuse/core'

export function useScreen() {
  const breakpoints = useBreakpoints(breakpointsTailwind)
  return {
    breakpoints,
  }
}

export function useColorSwitch() {
  const colorMode = useColorMode()
  if (colorMode.value === 'light')
    colorMode.preference = 'dark'
  else
    colorMode.preference = 'light'
}

/**
 * Calculate brightness value by RGB or HEX color.
 * Source: https://gist.github.com/w3core/e3d9b5b6d69a3ba8671cc84714cca8a4
 * @param color (String) The color value in RGB or HEX (for example: #000000 || #000 || rgb(0,0,0) || rgba(0,0,0,0))
 * @returns (Number) The brightness value (dark) 0 ... 255 (light)
 */
export function useColor(color?: MaybeRefOrGetter<string>) {
  const theColor = ref(toValue(color))

  const r = ref(0)
  const g = ref(0)
  const b = ref(0)

  const isHEX = computed(() => theColor.value !== undefined && theColor.value.indexOf('#') === 0)
  const fullHex = computed(() => isHEX.value && theColor.value?.length === 7)

  const isRGB = computed(() => theColor.value !== undefined && theColor.value.indexOf('rgb') === 0)

  if (isHEX.value) {
    const m = theColor.value?.substring(1).match(fullHex.value ? /(\S{2})/g : /(\S)/g)
    if (m) {
      r.value = Number.parseInt(m[0] + (fullHex.value ? '' : m[0]), 16)
      g.value = Number.parseInt(m[1] + (fullHex.value ? '' : m[1]!), 16)
      b.value = Number.parseInt(m[2] + (fullHex.value ? '' : m[2]!), 16)
    }
  }
  if (isRGB.value) {
    const m = theColor.value?.match(/\d+/g)
    if (m) {
      r.value = +m[0]
      g.value = +m[1]!
      b.value = +m[2]!
    }
  }

  const brightness = computed(() => ((r.value * 299) + (g.value * 587) + (b.value * 114)) / 1000)

  return {
    theColor,
    isRGB,
    isHEX,
    rgb: [r, g, b],
    brightness,
  }
}
