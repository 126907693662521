<script setup lang="ts">
const props = withDefaults(defineProps<{
  menu?: NavItem[]
  title?: string
  size?: 'default' | 'large'
  inline?: boolean | 'default' | 'always' | 'invert'
  divide?: boolean
  ulClass?: string | string[]
  liClass?: string | string[]
  iconClass?: string | string[]
  exactActiveClass?: string
}>(), {
  size: 'default',
  inline: false,
  divide: false,
  exactActiveClass: 'heading-link-active',
})

const listClasses = computed(() => [{
  'ics-pvrt9m': !props.inline,
  'ics-zhwhun': props.inline && props.inline !== 'always' && props.inline !== 'invert',
  'ics-8gpo6j': props.inline === 'always',
  'ics-qfb15w': props.inline === 'invert',
  'text-xl': props.size === 'large',
  // 'divide-transparent': !props.divide,
  // 'divide-accent-950/25 ': props.divide,
}, props.ulClass])

const itemClasses = computed(() => [{
  // 'w-full': !props.inline,
  // 'w-full sm:w-max': props.inline && props.inline !== 'always' && props.inline !== 'invert',
  // 'sm:w-full sm:max-w-none w-max max-w-50': props.inline === 'invert',

}, props.liClass])

const iconClasses = computed(() => [{
  'size-4 mr-1': !props.inline,
  'size-4 mr-1 sm:hidden': props.inline && props.inline !== 'always' && props.inline !== 'invert',
}, props.iconClass])
</script>

<template>
  <nav>
    <slot name="title">
      <header
        v-if="title || $slots.title"
        class="mb-2 font-700 tracking-normal font-flex"
      >
        {{ title }}
      </header>
    </slot>
    <ul
      class="ics-6e2qha"
      :class="listClasses"
    >
      <slot name="pre-li" />
      <li
        v-for="item in menu"
        :key="item.to"
        :class="itemClasses"
      >
        <slot
          name="default"
          :item
        >
          <NuxtLink
            class="inline-flex items-center text-truncate @hover:heading-link-hover transition f-py-4-8 font-flex-400-120 media-[(pointer:coarse)]:min-h-11 focusable heading-link-gray"
            :to="item.to"
            :exact-active-class
          >
            <div
              class="i-carbon-chevron-right"
              :class="iconClasses"
            />{{ item.label || item.title || item.name }} <div
              v-if="item.exit"
              class="i-carbon-launch ml-1.5 size-4.5 -translate-y-px"
            />
          </NuxtLink>
        </slot>
        <slot name="post-li" />
      </li>
    </ul>
  </nav>
</template>
