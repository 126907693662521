<script setup lang="ts">
withDefaults(defineProps<{
  pos?: 'left' | 'right'
  test?: boolean
}>(), {
  pos: 'left',
  test: false,
})
const dropdownEl = useCurrentElement<HTMLDivElement>()
const hovered = useElementHover(dropdownEl)
// const { focused } = useFocusWithin(dropdownEl)
const open = ref(false)
const close = refAutoReset(false, 1000)
const timing = ref<'delayed' | 'instant'>('delayed')
function closeOnNav(event: Event) {
  // (event.target as HTMLElement).blur()
  if ((event.target as HTMLElement).tagName === 'A' || (event.target as HTMLElement).classList.contains('close-dropdown')) {
    hovered.value = false
    open.value = false
    close.value = true
  }
}

watch(hovered, () => {
  if (hovered.value)
    open.value = true
  if (!hovered.value)
    open.value = false
})

watch(close, () => {
  if (close.value)
    timing.value = 'instant'
  if (!close.value)
    timing.value = 'delayed'
})

onClickOutside(dropdownEl, () => open.value = false)
onKeyStroke('Escape', () => open.value = false)
</script>

<template>
  <div
    class="relative inline-flex cursor-pointer"
    @mouseup="closeOnNav"
    @touchend="closeOnNav"
  >
    <div v-if="test">
      <div class="py-card px-card">
        Dropdown hovered: {{ hovered }}
      </div>
      <!-- <div class="px-card py-card">
        Dropdown focused: {{ focused }}
      </div> -->
      <div class="py-card px-card">
        Dropdown open: {{ open }}
      </div>
      <div class="py-card px-card">
        Dropdown close: {{ close }}
      </div>
      <div class="py-card px-card">
        Dropdown timing: {{ timing }}
      </div>
    </div>
    <div
      class="z-1 flex"
      @touchend="open = !open"
    >
      <slot
        name="title"
        :open="open"
        :close="close"
      />
    </div>
    <ClientOnly>
      <div
        v-if="$slots.content"
        class="absolute top-full z-9999 min-w-full overflow-hidden"
        :class="{ 'right-0': pos === 'right' }"
      >
        <Transition :name="timing">
          <div v-show="open && !close">
            <slot
              name="content"
              :open="open"
              :close="close"
            />
          </div>
        </Transition>
      </div>
    </ClientOnly>
  </div>
</template>

<style>
.delayed-enter-active,
.instant-enter-active {
  transition-property: transform, opacity;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1); /* ease-out */
}
.delayed-leave-active,
.instant-leave-active {
  transition-property: transform, opacity;
  transition-duration: 150ms, 50ms;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1); /* ease-in */
}

.delayed-enter-active,
.delayed-leave-active {
  transition-delay: 150ms;
}
.instant-enter-active,
.instant-leave-active {
  transition-delay: 0ms;
}

.delayed-enter-from,
.delayed-leave-to,
.instant-enter-from,
.instant-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}

/* .delayed-enter-from .dropdown-shadow, .delayed-leave-to .dropdown-shadow,
.instant-enter-from .dropdown-shadow, .instant-leave-to .dropdown-shadow {
  box-shadow: 0 0 #0000, 0 0 #0000;
} */
</style>
