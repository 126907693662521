<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'

const props = withDefaults(defineProps<{
  text: string
  pos?: 'top' | 'bottom' | 'left' | 'right'
  delay?: number
  prevent?: boolean
}>(), {
  pos: 'top',
  delay: 700,
})

const tooltipEl = ref<HTMLDivElement>()

const tooltipId = useId()

const posClasses = computed(() => ({
  'left-1/2 -top-1.5 -translate-x-1/2 -translate-y-full': props.pos === 'top',
  'left-1/2 -bottom-1.5 -translate-x-1/2 translate-y-full': props.pos === 'bottom',
  'top-1/2 -left-1.5 -translate-x-full -translate-y-1/2': props.pos === 'left',
  'top-1/2 -right-1.5 translate-x-full -translate-y-1/2': props.pos === 'right',
}))

const delayClass = computed(() => `group-hover:delay-${props.delay}`)

defineExpose({
  tooltipEl,
})

const closeTooltip = refAutoReset(false, 2500)
</script>

<template>
  <div class="group relative m-0">
    <ClientOnly>
      <div
        v-show="!closeTooltip && !prevent"
        :id="tooltipId"
        ref="tooltipEl"
        v-on-click-outside="() => closeTooltip = true"
        role="tooltip"
        class="pointer-events-none absolute z-9999 inline-block select-none whitespace-pre rounded-sm bg-icssilver-50/50 px-1 py-0.5 text-center text-sm text-icssilver-950 leading-tight hyphens-auto opacity-0 shadow-sm transition-opacity dark:bg-icsjet/38 dark:text-icssilver-50 dark:shadow-black/50 group-hover:opacity-100"
        :class="[posClasses, delayClass]"
      >
        <p>{{ text }}</p>
      </div>
    </ClientOnly>
    <slot />
  </div>
</template>
