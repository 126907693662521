<script setup lang="ts">
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

if (import.meta.client)
  gsap.registerPlugin(ScrollTrigger)

let gsapContext: ReturnType<typeof gsap.context>

const { menu, getNavItems } = useNav()
const { breakpoints } = useScreen()

const drop = ref(false)

const modal = ref(false)

const navbarEl = ref<HTMLElement>()
const navdropEl = ref<HTMLElement>()
const navmodalEl = ref<HTMLElement>()

const { activate: startDropTrap, deactivate: stopDropTrap } = useFocusTrap(navdropEl)

const { activate: startModalTrap, deactivate: stopModalTrap } = useFocusTrap(navmodalEl, {
  initialFocus: '#close-modal',
})

const isSmall = breakpoints.smallerOrEqual('sm')

function toggleNavdrop() {
  drop.value = !drop.value
}

function closeOnNav(event: Event) {
  const element = event.target as HTMLElement
  if (!element)
    return
  element.blur()
  if (element.tagName === 'A') {
    drop.value = false
    modal.value = false
  }
}

const onNavmodal = {
  afterEnter: (el: HTMLElement) => {
    disableBodyScroll(el)
    startModalTrap()
  },

  beforeLeave: (el: HTMLElement) => {
    stopModalTrap()
    enableBodyScroll(el)
  },
}

const onNavdrop = {
  afterEnter: () => {
    startDropTrap()
  },
  beforeLeave: () => {
    stopDropTrap()
  },
}

onClickOutside(navdropEl, (event) => {
  if (!drop.value)
    return
  event.stopPropagation()
  drop.value = false
})

onKeyStroke('Escape', () => {
  drop.value = false
  modal.value = false
})

const scrollTriggerProps = {
  duration: 0.2,
  ease: 'power1.inOut',
  scrollTrigger: {
    trigger: navbarEl.value,
    start: 'top top-=180',
    toggleActions: 'play none none reverse',
  },
}

onMounted(() => {
  gsapContext = gsap.context(() => {
    if (!navbarEl.value)
      return

    const q = gsap.utils.selector(navbarEl.value)
    const grid = q('.grid')
    const logo = q('.logo')
    const menu = q('.menu')

    gsap.set(grid, {
      'column-gap': 0,
    })
    gsap.set(logo, {
      width: 0,
    })
    gsap.set(menu, {
      'margin-right': 32,
    })

    gsap.to(grid, {
      'column-gap': 'clamp(0rem, -0.9184rem + 4.0816vw, 3rem)',
      ...scrollTriggerProps,
    })
    gsap.to(logo, {
      width: 112,
      ...scrollTriggerProps,
    })
    gsap.to(menu, {
      'margin-right': 0,
      ...scrollTriggerProps,
    })
  })
})

onUnmounted(() => {
  if (gsapContext)
    gsapContext.revert()
})
</script>

<template>
  <!-- NAVMODAL -->
  <Teleport to="body">
    <Transition
      name="navmodal"
      v-on="onNavmodal"
    >
      <!-- Navmodal -->
      <div
        v-if="modal"
        ref="navmodalEl"
        class="fixed inset-x-0 z-9999 h-screen overflow-y-auto"
      >
        <div class="sticky top-0 z-1 bg-white py-2 shadow dark:bg-icsnight-950 dark:shadow-black/50">
          <BlockContent class="flex items-center justify-between gap-6">
            <IcsLogo
              class="w-28 -m-1"
              @click="modal = false"
            />
            <!-- navmodal button -->
            <AButton
              id="close-modal"
              label="Close"
              size="fluid"
              color="red"
              icon="i-carbon-close-large"
              @click="modal = false"
            />
          </BlockContent>
        </div>
        <Navmenu
          class="py-6"
          @click="closeOnNav"
        />
      </div>
    </Transition>
  </Teleport>

  <!-- NAVBAR & NAVDROP -->
  <div class="ics-rrox3y">
    <!-- Navbar -->
    <div
      ref="navbarEl"
      class="bg-white py-2 shadow-md dark:bg-icsnight dark:shadow-black/50"
    >
      <div class="grid auto-cols-[min-content_auto_min-content] grid-flow-col items-center w-content">
        <div class="logo w-0 flex overflow-hidden -ml-1">
          <IcsLogo class="w-28 shrink-0" />
        </div>
        <div class="menu">
          <ANav
            :menu="menu"
            inline="always"
            class="hidden w-full f-max-w-672-800 sm:block"
            ul-class="!gap-x-0 justify-between"
            li-class=""
          >
            <template #default="{ item }">
              <ADropdown>
                <template #title>
                  <NuxtLink
                    class="flex items-center text-truncate whitespace-nowrap text-center transition f-text-10-16 f-h-32-48 f-px-12-24 f-mx--12--24 button-label media-[(pointer:coarse)]:min-h-11 focusable heading-link-bright"
                    :to="item.to"
                    active-class="heading-link-active"
                  >
                    {{ item.label || item.title || item.name }}
                  </NuxtLink>
                </template>
                <template
                  v-if="item.children && !drop"
                  #content
                >
                  <div class="pt-5">
                    <ANav
                      :menu="getNavItems(item.children)"
                      class="rounded-sm bg-white py-2 f-pl-6-12 f-pr-12-24 dark:bg-icsnight"
                    />
                  </div>
                </template>
              </ADropdown>
            </template>
          </ANav>
        </div>
        <!-- navbar button -->
        <AButton
          :label="drop ? 'Close' : 'Menu'"
          size="fluid"
          :color="drop ? 'red' : 'white'"
          :icon="drop ? 'i-carbon-close-large' : 'i-carbon-menu'"
          @click="isSmall ? modal = true : toggleNavdrop()"
        />
      </div>
    </div>

    <!-- Navdrop -->
    <Transition
      name="navdrop"
      v-on="onNavdrop"
    >
      <Navmenu
        v-show="drop"
        ref="navdropEl"
        class="absolute inset-x-0 py-6 shadow-lg -z-1 dark:shadow-black/38"
        @click="closeOnNav"
      />
    </Transition>
  </div>
</template>

<style>
.navdrop-enter-active,
.navdrop-leave-active {
  transition-property: transform;
  transition-duration: 150ms;
}

.navdrop-enter-from,
.navdrop-leave-to {
  transform: translateY(-100%);
}

.navmodal-enter-active,
.navmodal-leave-active {
  transition-property: opacity;
  transition-duration: 150ms;
}

.navmodal-enter-from,
.navmodal-leave-to {
  opacity: 0;
}

.navdrop-enter-active {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.navdrop-leave-active {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.navdrop-enter-to,
.navdrop-leave-from {
  transform: translateY(0%);
}

@media (prefers-reduced-motion) {
  .navdrop-enter-active,
  .navdrop-leave-active {
    transition-property: opacity;
    transition-duration: 150ms;
  }
}
</style>
