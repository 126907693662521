<script setup lang="ts">
import { AButton } from '#components'

defineProps<{
  color?: InstanceType<typeof AButton>['$props']['color']
  size?: InstanceType<typeof AButton>['$props']['size']
  disable?: InstanceType<typeof AButton>['$props']['disable']
  iconOnly?: InstanceType<typeof AButton>['$props']['iconOnly']
}>()

const colorMode = useColorMode()
const isLight = computed(() => colorMode.value === 'light')
</script>

<template>
  <ClientOnly>
    <AButton
      :label="isLight ? 'Use Dark Mode' : 'Use Light Mode'"
      :icon="isLight ? 'i-carbon-moon' : 'i-carbon-sun'"
      :color
      :size
      :disable
      :icon-only
      @click="useColorSwitch()"
    />
  </ClientOnly>
</template>
