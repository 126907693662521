export function useNav() {
  const route = useRoute()
  // get the routes generated by vue-router
  const router = useRouter()
  interface TheNav {
    list: NavItem[]
    index: Record<NavItem['name'], NavItem>
  }

  const { data: theNav } = useAsyncData<TheNav>(
    'nav',
    async () => buildNavItems(),
    {
      // eslint-disable-next-line ts/no-unsafe-return
      getCachedData: (key, nuxtApp) => nuxtApp.payload.data[key],
    },
  )

  const menu = computed(() => theNav.value?.list.filter(item => item.name !== 'index' && item.name !== 'rand').filter(item => splitPath(item.to).length === 1).sort((a, b) => (a.order ?? 99) - (b.order ?? 100)))

  // computed path split into array of slugs
  const pathArray = computed(() => splitPath(route.path))

  // computed array of links for TheBreadcrumbs component
  const breadcrumbs = computed(() => {
    const breadcrumbArray = pathArray.value.map((slug, i) => {
      const name = `${pathArray.value.slice(0, i + 1).join('-')}`
      const crumb = getNavItem(name)
      return {
        title: crumb?.label ?? crumb?.title ?? crumb?.name ?? slug.replaceAll('-', ' '),
        to: `/${pathArray.value.slice(0, i + 1).join('/')}`,
      }
    })
    return breadcrumbArray
  })

  const location = computed(() => getNavItem(route.name as string))

  function getNavItem(itemName: NavItem['name']) {
    return theNav.value?.index[itemName]
  }

  function getNavItems(itemNames: NavItem['name'][]) {
    return itemNames.map((name) => {
      if (!theNav.value?.index[name])
        return null
      else
        return theNav.value?.index[name]
    }).filter((item): item is NavItem => !!item).sort((a, b) => (a.order ?? 99) - (b.order ?? 100))
  }

  async function buildNavItems() {
    const theRoutes = router.getRoutes()
    const list: NavItem[] = []
    const index: Record<NavItem['to'], NavItem> = {}

    theRoutes.forEach((route) => {
      if (route.path.includes(':'))
        return // filter out dynamic routes

      if (route.name === undefined)
        return // filter out redirects

      const navItem = transformRouteToNavItem(route)

      list.push(navItem)

      index[navItem.name] = navItem
    })

    const industries: NavItem[] = (await $fetch<Industry[]>('/api/items/industries')).map(industry => ({
      to: `/industries/${industry.slug}`,
      name: `industries-${industry.slug}`,
      label: industry.name,
      order: industry.sort as number,
    }))

    const trexonFamily = {
      to: 'https://trexonglobal.com/about-us',
      name: 'about-trexon-family',
      title: 'The Trexon Family',
      exit: true,
      order: 3,
    }

    const careers = {
      to: 'https://trexonglobal.com/careers/',
      name: 'careers',
      title: 'Careers',
      exit: true,
      order: 4,
    }

    const contactTrexon = {
      to: 'https://trexonglobal.com/get-in-touch/',
      name: 'contact-trexon',
      title: 'Contact Trexon',
      exit: true,
      order: 1,
    }

    list.push(trexonFamily, careers, contactTrexon, ...industries)

    const aboutItem = list.find(item => item.name === 'about')
    const industriesItem = list.find(item => item.name === 'industries')
    const contactItem = list.find(item => item.name === 'contact')

    if (aboutItem?.children)
      aboutItem.children?.push(trexonFamily.name, careers.name)

    else if (aboutItem)
      aboutItem.children = [trexonFamily.name, careers.name]

    if (industriesItem?.children)
      industriesItem.children?.push(...industries.map((item => item.name)))

    else if (industriesItem)
      industriesItem.children = industries.map((item => item.name))

    if (contactItem?.children)
      contactItem.children?.push(contactTrexon.name)

    else if (contactItem)
      contactItem.children = [contactTrexon.name]

    index[trexonFamily.name] = trexonFamily
    index[careers.name] = careers
    index[contactTrexon.name] = contactTrexon
    industries.forEach((item) => {
      index[item.name] = item
    },

    )
    return {
      list,
      index,
    }
  }

  return {
    route,
    theNav,
    menu,
    pathArray,
    breadcrumbs,
    location,
    getNavItem,
    getNavItems,
  }
}
