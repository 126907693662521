<script setup lang="ts">
const { menu, getNavItems } = useNav()
</script>

<template>
  <nav class="min-h-full bg-icsgray-50 dark:bg-icsnight-800">
    <BlockContent class="space-y-4">
      <ul class="grid grid-cols-1 gap-y-5 f-gap-x-32-48 lg:grid-cols-3 sm:grid-cols-2">
        <li
          v-for="item in menu"
          :key="item.name"
        >
          <NuxtLink
            :to="item.to"
            class="group h-11 inline-flex items-center justify-between text-xl uppercase font-flex-440-151-600 focusable heading-link-bright"
          >
            {{ item.title || item.name }}
          </NuxtLink>
          <div
            v-if="item.children"
            class="space-y-4"
          >
            <hr class="b-icssilver dark:b-icsjet">
            <ANav :menu="getNavItems(item.children)">
              <template #default="{ item: subitem }">
                <NuxtLink
                  class="flex items-center text-truncate @hover:heading-link-hover transition f-py-4-8 font-flex-400-120 media-[(pointer:coarse)]:min-h-11 focusable heading-link-gray"
                  :to="subitem.to"
                  :exact-active-class="item.name === 'industries' ? '' : 'heading-link-active'"
                >
                  <div class="i-carbon-chevron-right mr-1 size-4" />{{ subitem.label || subitem.title || subitem.name }} <div
                    v-if="subitem.exit"
                    class="i-carbon-launch ml-1.5 size-4.5 -translate-y-px"
                  />
                </NuxtLink>
              </template>
            </ANav>
          </div>
        </li>
      </ul>
      <hr class="b-icssilver dark:b-icsjet">
      <ButtonThemeToggle
        color="gray"
        size="small"
      />
    </BlockContent>
  </nav>
</template>
