<script setup lang="ts">
import { NuxtLink } from '#components'

// button properties
const props = withDefaults(defineProps<{
  label?: string
  to?: string
  href?: string
  newTab?: boolean
  size?: 'default' | 'small' | 'large' | 'xlarge' | 'fluid'
  fullWidth?: boolean
  color?: 'white' | 'gray' | 'blue' | 'cyan' | 'red'
  material?: 'solid' | 'empty'
  icon?: string
  iconSide?: 'left' | 'right'
  iconOnly?: boolean
  disable?: boolean
  bodyClass?: string
  labelClass?: string
}>(), {
  size: 'default',
  color: 'white',
  material: 'solid',
  iconSide: 'left',
})

// DOM elements
const bodyEl = ref<HTMLDivElement>()
const { width: solidElWidth } = useElementSize(bodyEl)

// check if the solid background element of the button is wide enough for touch-device accessibility
const solidWidthOk = computed(() => !bodyEl.value ? true : solidElWidth.value >= 44)
// if there the solid element
const negativeMargin = computed(() => {
  if (solidWidthOk.value)
    return
  const offset = (44 - solidElWidth.value) / 2
  return `media-[(pointer:coarse)]:-mx-[${Math.round(offset)}px]`
})

// props processing
const element = computed(() => {
  if (!props.to && !props.href)
    return 'button'
  return props.to ? NuxtLink : 'a'
})
const target = computed(() => props.newTab ? '_blank' : null)

// label + icon elements shared classes
const textColorClasses = computed(() => [
  props.disable ? '' : 'drop-shadow-sm',
  props.material === 'solid'
    ? {
        // solid material colors
        'text-icssilver-950 group-hover:text-icsblue-600 dark:text-icssilver-50 dark:group-hover:text-icscyan-300': props.color === 'white',
        'text-white': props.color !== 'white',
      }
    : {
        // empty material colors
        'text-white group-hover:text-icscyan': props.color === 'white',
        'text-icsgray-800 group-hover:text-icsgray-600 dark:text-icsgray-500 dark:group-hover:text-icsgray-300': props.color === 'gray',
        'text-icsblue group-hover:text-icsblue-600 dark:text-icsblue-500 dark:group-hover:text-icsblue-300': props.color === 'blue',
        'text-icscyan group-hover:text-icscyan-300 dark:text-icscyan-400 dark:group-hover:text-icscyan-200': props.color === 'cyan',
        'text-icsred group-hover:text-icsred-700 dark:group-hover:text-icsred-400': props.color === 'red',
      },
])

// body element classes
const bodyClasses = computed(() => [
  {
    'gap-1.75': props.size === 'default',
    'gap-1.5': props.size === 'small',
    'gap-2': props.size === 'large',
    'gap-3': props.size === 'xlarge',
    'f-gap-5-7': props.size === 'fluid',
  },
  props.material === 'solid'
    ? {
        'media-[(pointer:coarse)]:min-w-11': !bodyEl.value,
        // default size
        'min-h-10': props.size === 'default',
        'px-3': props.size === 'default' && !props.iconOnly,
        'size-10': props.size === 'default' && props.icon && props.iconOnly,
        // small size
        'min-h-8 gap-1.25': props.size === 'small',
        'px-2': props.size === 'small' && !props.iconOnly,
        'size-8': props.size === 'small' && props.icon && props.iconOnly,
        // large size
        'min-h-12': props.size === 'large',
        'px-4': props.size === 'large' && !props.iconOnly,
        'size-12': props.size === 'large' && props.icon && props.iconOnly,
        // xlarge size
        'min-h-14': props.size === 'xlarge',
        'px-6': props.size === 'xlarge' && !props.iconOnly,
        'size-14': props.size === 'xlarge' && props.icon && props.iconOnly,
        // fluid size
        'f-min-h-32-48': props.size === 'fluid',
        'f-px-12-16': props.size === 'fluid' && !props.iconOnly,
        'f-w-32-48 f-h-32-48': props.size === 'fluid' && props.icon && props.iconOnly,
        // colors
        'bg-white dark:bg-icsjet-950': props.color === 'white' && !props.disable,
        'bg-icsgray-800': props.color === 'gray',
        'group-hover:bg-icsgray-700': props.color === 'gray' && !props.disable,
        'bg-icsblue dark:bg-icsblue-700': props.color === 'blue',
        'group-hover:bg-icsblue-700 dark:group-hover:bg-icsblue-600': props.color === 'blue' && !props.disable,
        'bg-icscyan dark:bg-icscyan-600': props.color === 'cyan',
        'group-hover:bg-icscyan-400 dark:group-hover:bg-icscyan-500': props.color === 'cyan' && !props.disable,
        'bg-icsred ': props.color === 'red',
        'group-hover:bg-icsred-600': props.color === 'red' && !props.disable,
        // drop shadow
        'shadow dark:shadow-black/80': !props.disable,

      }
    : 'bg-transparent group-hover:bg-transparent',
  props.bodyClass,
])

// label element classes
const labelClasses = computed(() => [{
  'text-sm': props.size === 'default', // default
  'text-xs': props.size === 'small', // small
  'text-base': props.size === 'large', // large
  'text-lg': props.size === 'xlarge', // xlarge
  'f-text-12-16': props.size === 'fluid', // fluid
}, props.labelClass])

const iconClasses = computed(() => [{
  'pointer-events-none': !props.iconOnly, // prevent icon from blocking any pointer events
  'text-base': props.size === 'default', // default
  'text-sm': props.size === 'small', // small
  'text-xl': props.size === 'large', // large,
  'text-2xl': props.size === 'xlarge', // xlarge,
  'f-text-14-20': props.size === 'fluid', // fluid
}, props.icon])

// overlay element classes
// const overlayClasses = computed(() => ({
// 'group-hover:bg-icssilver-700 group-hover:opacity-100': !props.color || props.color === 'gray',
// 'group-active:bg-icsblue group-active:opacity-100': pr ops.color === 'blue',
// 'bg-icscyan group-active:opacity-100': props.color === 'cyan',
// 'group-hover:bg-icsred group-hover:opacity-40': props.color === 'red',
// }))

// root element classes
const rootClasses = computed(() => [
  // prevent abnormal large gap between small buttons on touch devices
  negativeMargin.value,
  // disable
  { 'pointer-events-none opacity-60': props.disable },
])
</script>

<template>
  <!-- root element -->
  <component
    :is="element"
    :to="to"
    :href="href"
    :target="target"
    class="ics-aab24b group"
    :class="rootClasses"
  >
    <!-- body element -->
    <div
      ref="bodyEl"
      class="ics-g4g97w"
      :class="bodyClasses"
    >
      <!-- overlay element -->
      <!-- <div
        v-show="!disable"
        class="absolute inset-0 z-1 opacity-0 transition-opacity"
        :class="overlayClasses"
      /> -->
      <!-- icon element -->
      <div
        v-if="icon"
        class="ics-8igw5y"
        :class="[textColorClasses, { 'flex-order-last': iconSide === 'right' }]"
      >
        <div
          class="transition-color"
          :class="[iconClasses, iconOnly ? '' : {
            '-ml-1': size === 'default' || size === 'small',
            '-ml-1.25': size === 'large',
            '-ml-1.5': size === 'xlarge',
            'f-ml--4--5': size === 'fluid',
          }]"
        />
        <span
          v-if="iconOnly"
          class="sr-only bg-white text-black"
        >{{ label }}</span>
      </div>

      <!-- label element -->
      <div
        v-if="(label || $slots.default) && !iconOnly"
        class="ics-wb5vpp"
        :class="[textColorClasses, labelClasses]"
      >
        <slot>{{ label }}</slot>
      </div>

      <!-- new-tab icon element -->
      <div
        v-if="newTab && !icon"
        class="ics-8igw5y"
        :class="[textColorClasses, {
          '-mr-1': size === 'default' || size === 'small',
          '-mr-1.25': size === 'large',
          '-mr-1.5': size === 'xlarge',
          'f-mr--4--5': size === 'fluid',
        }]"
      >
        <span
          class="ics-ezetk5"
          :class="iconClasses"
        />
      </div>

      <!-- overlay element -->
      <!-- <div
        v-if="!materialEmpty && !disable"
        class="absolute-inset bg-neutral opacity-0 mix-blend-multiply transition-all duration-75 group-active:opacity-38"
        :class="overlayClasses"
      /> -->
    </div>
  </component>
</template>
