<script setup lang="ts">
const siteConfig = useSiteConfig()

const route = useRoute()

const title = computed(() => route.meta.title
  ? route.meta.title as string
  : siteConfig.name)

const description = computed(() => route.meta.description
  ? route.meta.description as string
  : 'ICS is an ISO 9001:2015 & AS9100D certified supplier of specialized cable assemblies and wire harnesses based in Colorado, USA.')

useSeoMeta({
  title: () => title.value,
  twitterTitle: () => title.value,
  description: () => description.value,
  twitterDescription: () => description.value,
  ogImage: '/assets/ics-social-thumb.jpg',
  ogImageWidth: 1200,
  ogImageHeight: 630,
  ogImageType: 'image/jpeg',
  twitterImage: '/assets/ics-social-thumb.jpg',
  twitterCard: 'summary_large_image',
})
</script>

<template>
  <NuxtErrorBoundary>
    <template #error="{ error, clearError }">
      <div style="padding: 0.75rem; font-family: 'Roboto Mono'; font-weight: 500">
        <p style="margin-bottom: 1rem">
          {{ error }}
        </p>
        <button
          style="border: solid black 1px; padding: 0 1rem"
          @click="clearError"
        >
          CLEAR
        </button>
      </div>
    </template>
    <header
      role="banner"
      class="relative z-5000 flex-none bg-white dark:bg-icsnight"
    >
      <BlockContent>
        <TheHeader />
        <hr class="b-icsgray dark:b-icsnight-800">
      </BlockContent>
    </header>
    <TheNav />
    <main class="flex-1">
      <NuxtPage />
    </main>
    <footer class="relative z-2000 flex-none bg-icssilver-50 pb-4 pt-6 shadow-md dark:bg-icsjet-900">
      <BlockContent>
        <TheFooter />
      </BlockContent>
    </footer>
  </NuxtErrorBoundary>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wdth,wght,YTDE,YTLC,YTUC@8..144,25..151,100..1000,-222,550,528..760&display=swap');

.page-enter-active,
.page-leave-active {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 75ms;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>
