export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  if (!config.public.icsProd) {
    nuxtApp.hook('vue:error', (err, target, info) => {
      console.log(err)
      console.log(target)
      console.log(info)
    })

    nuxtApp.hook('app:error', (err) => {
      console.log(err)
    })
  }
})
