<script setup lang="ts">
const now = new Date()
const year = ref(now.getUTCFullYear())
const trexonMenu: NavItem[] = [
  {
    name: 'trexon',
    title: 'Trexon',
    to: 'https://www.trexonglobal.com/',
  },
  {
    name: 'cicoil',
    title: 'Cicoil',
    to: 'https://www.cicoil.com/',
  },
  {
    name: 'ez-form',
    title: 'EZ Form',
    to: 'https://ezform.com/',
  },
  {
    name: 'fec',
    title: 'FEC',
    to: 'https://feccables.com/',
  },
  {
    name: 'hydro-group',
    title: 'Hydro Group',
    to: 'https://www.hydrogroup-uk.com/',
  },
  {
    name: 'intelliconnect',
    title: 'Intelliconnect',
    to: 'https://www.intelliconnectgroup.com/',
  },
  {
    name: 'pci',
    title: 'PCI',
    to: 'https://powerconnector.com/',
  },
  {
    name: '603-manufacturing',
    title: '603 Manufacturing',
    to: 'https://603manufacturing.com/',
  },
  {
    name: 'cepa',
    title: 'CEPA',
    to: 'https://cepainc.com/',
  },
]
const { menu, getNavItems } = useNav()
</script>

<template>
  <div class="grid f-gap-16-96 sm:grid-flow-col">
    <div class="flex-auto f-max-w-224-272 space-y-4">
      <IcsLogo class="w-full" />
      <div>
        <p class="whitespace-nowrap text-sm heading-strong">
          Integrated Cable Systems
        </p>
        <address class="leading-5 not-italic opacity-80">
          410 S Sunset St, Suite A<br>Longmont CO, 80501
        </address>
      </div>
      <nav>
        <div>Our Partner Companies</div>
        <ul class="max-w-sm flex flex-wrap gap-x-4">
          <li
            v-for="item in trexonMenu"
            :key="item.name"
          >
            <NuxtLink
              :to="item.to"
              class="inline-flex items-center text-sm media-[(pointer:coarse)]:min-h-11 link"
            >
              {{ item.title }}
            </NuxtLink>
          </li>
        </ul>
      </nav>
      <NuxtPicture
        src="de0b7314-498b-4d6d-b60c-89f5c6d92501"
        :img-attrs="{
          class: 'mt-4',
        }"
        :placeholder="[100]"
      />
      <ButtonThemeToggle color="gray" />
    </div>
    <div>
      <ul class="grid grid-cols-2 gap-x-6 gap-y-8 2xl:grid-cols-6 lg:grid-cols-3">
        <li
          v-for="item in menu"
          :key="item.name"
        >
          <NuxtLink
            class="inline-flex items-center text-sm @hover:heading-link-hover transition f-py-4-8 font-flex-400-120 media-[(pointer:coarse)]:min-h-11 focusable heading-link-gray"
            :to="item.to"
          >
            {{ item.label || item.title || item.name }} <div
              v-if="item.exit"
              class="i-carbon-launch ml-1.5 size-4.5 -translate-y-px"
            />
          </NuxtLink>
          <ul
            v-if="item.children"
            class="text-sm font-300"
          >
            <li
              v-for="child in getNavItems(item.children)"
              :key="child.name"
            >
              <NuxtLink
                class="inline-flex items-center text-sm transition f-py-4-8 media-[(pointer:coarse)]:min-h-11 @hover:link focusable"
                :to="child.to"
              >
                {{ child.label || child.title || child.name }} <div
                  v-if="child.exit"
                  class="i-carbon-launch ml-1.5 size-3 -translate-y-px"
                />
              </NuxtLink>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <div class="be-ix-link-block" />
  <div class="mt-8 text-center text-xs">
    © {{ year }} Integrated Cable Systems, a Trexon Company. All rights reserved.
  </div>
</template>
