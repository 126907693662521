import type { RouteRecordNormalized, RouteRecordRaw } from '#vue-router'

export function deepClone<T extends object>(obj: T) {
  return JSON.parse(JSON.stringify(obj)) as T
}

export function listToIndex<T extends Record<string, any>>(theItems?: T[] | null, key: keyof T = 'id') {
  const itemIndex: Record<string, T> = {}
  if (theItems) {
    theItems.forEach((item) => {
      if (item[key] !== undefined)
        itemIndex[item[key]] = item
    })
  }
  return itemIndex
}

export function transformJunctionDataToIndex<T extends Record<string, any>>(theJunctions?: T[] | null, key: keyof T = 'id') {
  const junctionIndex: Record<number, T> = {}
  if (theJunctions) {
    theJunctions.forEach((item) => {
      if (item[key] !== undefined)
        junctionIndex[item[key]] = item
    })
  }
  return junctionIndex
}

export function splitPath(path: string) {
  if (path.endsWith('/'))
    path = path.slice(0, -1)
  return path.substring(1).split(/[/#]/)
}

export function transformRouteToNavItem(route: RouteRecordNormalized | RouteRecordRaw): NavItem {
  return {
    to: route.path,
    name: (route.name ?? route.path.replaceAll('/', '-')) as string,
    title: (route.meta?.title ?? route.name) as string,
    label: route.meta?.label as string,
    icon: route.meta?.icon as string,
    order: route.meta?.order as number,
    children: !route.children || route.children?.length > 0 ? route.children?.map(child => child.name as string) : undefined,
  }
}

export function formatFieldKey(key: string) {
  const underscoresToArray = key.split('_')
  const capitalized = underscoresToArray.map(str => [str[0]?.toUpperCase(), str.slice(1)].join('')).join(' ')
  return capitalized
}
